import ReactMarkdown from "react-markdown";

import "./MarkdownContentStyles.css";

type MarkdownContentProps = {
  children: string;
};

const MarkdownContent = ({ children }: MarkdownContentProps) => (
  <ReactMarkdown className="message-formatted">{children}</ReactMarkdown>
);

export default MarkdownContent;
