import { TableCell } from "@bolt/components";
import { memo } from "react";

type DatasetTableCellProps = {
  value: string | number;
  columnName: string;
};

const DatasetTableCell = ({ value, columnName }: DatasetTableCellProps) => {
  return (
    <TableCell>
      {columnName.includes("_DATE")
        ? new Date(value).toLocaleDateString()
        : value}
    </TableCell>
  );
};

export default memo(DatasetTableCell);
