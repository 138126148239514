import { FETCH_USER_AGENT } from "../config";
import { ChatMessageAssistantParts } from "../types";

export class DetailsService {
  static details: undefined | Record<string, { description: string }>;
  static info: undefined | ChatMessageAssistantParts;

  static async fetchDetails(accessToken: string): Promise<void> {
    const DETAILS_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/info/metrics";

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(DETAILS_ENDPOINT, requestOptions);
      if (response.status > 299) {
        throw new Error();
      }
      this.details = await response.json();
    } catch (error: any) {
      console.error(error.message);
    }

    return;
  }

  static getDetails(key: string): string | undefined {
    return this.details?.[key]?.description;
  }

  static async getAbout(accessToken: string) {
    if (this.info) {
      return this.info;
    }

    const INFO_ENDPOINT: string =
      process.env.REACT_APP_BACKEND_URL + "/api/v1/info";

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(INFO_ENDPOINT, requestOptions);
      if (response.status > 299) {
        throw new Error();
      }
      const responseJson = await response.json();

      // TODO: Backend can better prepare the response. Now it needs to be adjusted
      const replacedQuotes = responseJson.description.replaceAll("'", '"');

      this.info = JSON.parse(replacedQuotes);

      return this.info;
    } catch (error: any) {
      console.error(error.message);
      return null;
    }
  }
}
