import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "@bolt/foundations/theme";
import "@bolt/components/styles";
import UserInfoContextWrapper from "./contexts/UserInfoContext";
import ChatDetailsContextWrapper from "./contexts/ChatContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <UserInfoContextWrapper>
      <ChatDetailsContextWrapper>
        <App />
      </ChatDetailsContextWrapper>
    </UserInfoContextWrapper>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
