import chunk from "lodash/chunk";
import SkeletonTextBlock from "./SkeletonTextBlock";
import { useEffect, useState } from "react";
import { useUserInfoContext } from "../contexts/UserInfoContext";
import { DetailsService } from "../services/DetailsSetvice";

import "./AssistantWelcomeMessageStyles.css";

type AssistantWelcomeMessageProps = {
  expirationGuard: () => Promise<string>;
};

const AssistantWelcomeMessage = ({
  expirationGuard,
}: AssistantWelcomeMessageProps) => {
  const [metricNames, setMetricNames] = useState<string[]>([]);
  const userInfo = useUserInfoContext()!;

  useEffect(() => {
    expirationGuard();

    if (userInfo.accessToken) {
      DetailsService.fetchDetails(userInfo.accessToken).then(() => {
        setMetricNames(
          Object.keys(DetailsService.details || {}).map((metricName) =>
            metricName.replaceAll("_", " "),
          ),
        );
      });
    }
    // eslint-disable-next-line
  }, [userInfo.accessToken]);

  // Note: Join metrics array with ", " but the last one with " and ".
  /*
  const metricNamesEnlisted = metricNames?.length
    ? metricNames.slice(0, -1).join(", ") + " and " + metricNames.slice(-1)
    : "";
  */

  const metricColumns = chunk(metricNames, Math.ceil(metricNames.length / 3));

  const metricsEnlisted = metricColumns.map((metriCells) => (
    <td>
      <ul>
        {metriCells.map((metricName) => (
          <li key={metricName}>{metricName}</li>
        ))}
      </ul>
    </td>
  ));

  if (!metricsEnlisted.length) {
    return <SkeletonTextBlock />;
  }

  return (
    <div className="message-formatted welcome-message-bubble-wrapper">
      <p>
        {
          "Hello, I'm a chatbot assistant. I support you with data related to the following metrics:"
        }
        <table className="metrics-table">
          <tbody>{metricsEnlisted}</tbody>
        </table>
        {"Feel free to ask me anything about them."}
      </p>
      <p className="welcome-message-footer-text">
        {
          "For more information, please click the 'About' button located on the left panel."
        }
      </p>
    </div>
  );
};

export default AssistantWelcomeMessage;
