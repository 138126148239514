import {
  Icon,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@bolt/components";
import { DatasetTableData, DatasetTableSchemaFields } from "../types";
import DownloadCSVButton from "./DownloadCSVButton";
import { memo, useState } from "react";

import "./DatasetTableStyles.css";
import DatasetTableCell from "./DatasetTableCell";
import TablePagination, { PaginationState } from "./TablePagination";

type DatasetTableProps = {
  fields: DatasetTableSchemaFields;
  data: DatasetTableData;
  showDownloadCSVButton?: boolean;
};

const CSV_FILENAME = "CSV_ChatWithData-data";

function mapPropsToCSVData(
  fields: DatasetTableSchemaFields,
  data: DatasetTableData,
): Array<Array<string | number>> {
  const CSVdata: Array<Array<string | number>> = [
    fields.map((field) => field.name),
  ];

  data.forEach((dataRow) => {
    CSVdata.push(Object.keys(dataRow).map((keyName) => dataRow[keyName]));
  });

  return CSVdata;
}

const DATA_ROWS_PER_PAGE = 20;

const DatasetTable = ({
  fields,
  data,
  showDownloadCSVButton = true,
}: DatasetTableProps) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: DATA_ROWS_PER_PAGE,
  });

  const [orderBy, setOrderBy] = useState(fields?.[0].name || "index");
  const [orderDirection, setOrderDirection] = useState(1);

  const dataCSV = mapPropsToCSVData(fields, data);

  const tableHeaders = fields || [];
  const tableDataSorted = [...data].sort((dataRow1, dataRow2) =>
    dataRow1[orderBy] < dataRow2[orderBy]
      ? -1 * orderDirection
      : 1 * orderDirection,
  );

  const tableDataSortedPaginated = tableDataSorted.slice(
    pagination.pageIndex * pagination.pageSize,
    (pagination.pageIndex + 1) * pagination.pageSize,
  );

  return (
    <>
      {showDownloadCSVButton && (
        <div className="btn-wrapper-csv">
          <DownloadCSVButton
            fileName={CSV_FILENAME}
            data={dataCSV}
            className="btn-download-csv"
          />
        </div>
      )}
      <div className="dataset-table-wrapper">
        <Table density="default" variant="line" className="table-dataset">
          <TableHeader>
            <TableRow>
              {tableHeaders.map((tableField) => (
                <TableHead
                  className="table-header-cell"
                  key={tableField.name + "-" + tableField.type}
                  onClick={() => {
                    setOrderBy(tableField.name);

                    if (orderBy === tableField.name && orderDirection === 1) {
                      setOrderDirection(-1);
                    } else {
                      setOrderDirection(1);
                    }
                  }}
                >
                  {tableField.name}
                  {tableField.name === orderBy && (
                    <Icon
                      className="table-header-cell-arrow"
                      icon={orderDirection === 1 ? "arrow-down" : "arrow-up"}
                    />
                  )}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableDataSortedPaginated.map((dataRow, index) => (
              <TableRow key={index}>
                {Object.keys(dataRow).map((keyName: string) => (
                  <DatasetTableCell
                    key={keyName}
                    value={dataRow[keyName]}
                    columnName={keyName}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          pagination={pagination}
          setPagination={setPagination}
          dataRows={tableDataSorted}
        />
      </div>
    </>
  );
};

export default memo(DatasetTable);
