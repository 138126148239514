import { pick } from "lodash";
import { FETCH_USER_AGENT } from "../config";
import {
  ChatDetails,
  ChatMessageAssistant,
  ChatRequest,
  DatasetTableSchemaFields,
} from "../types";

type DataArray = Array<Array<string | number>>;
type ChatDetailsformResponse = {
  chat_id: string;
  message_id: string;
  headers: DatasetTableSchemaFields;
  data: DataArray;
  description: string;
  details: {
    metric_name: string;
    parameters: Record<string, string>;
  };
};

type ReturnedContent = {
  error?: boolean;
  message?: string;
  headers?: DatasetTableSchemaFields;
  data?: DataArray;
};

export class ChatGpcService {
  static abortController: AbortController | undefined;

  static async abortRequest() {
    this.abortController?.abort();
  }

  static async askQuestion(
    chatRequest: ChatRequest,
    accessToken: string,
    logOutCallback: () => void,
    setChatDetails: React.Dispatch<React.SetStateAction<ChatDetails | null>>,
    chatDetails?: ChatDetails,
  ): Promise<ChatMessageAssistant> {
    this.abortRequest();
    this.abortController = new AbortController();
    const { chatId } = chatDetails || {};
    const CHAT_ENDPOINT =
      process.env.REACT_APP_BACKEND_URL +
      "/api/v1/chats" +
      (chatId ? `/${chatId}/messages` : "");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      body: JSON.stringify(chatRequest),
      signal: this.abortController.signal,
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(CHAT_ENDPOINT, requestOptions);

      if (response.status > 299) {
        // If Unauthorized - than log out
        if (response.status === 401) {
          logOutCallback();
        }

        throw new Error();
      }

      const responseJson = await response.json();

      if (responseJson.chat_id && responseJson.message_id) {
        setChatDetails((chatDetails) => ({
          ...chatDetails,
          chatId: responseJson.chat_id,
          messageId: responseJson.message_id,
        }));
      }

      return {
        ...responseJson.message,
        role: "assistant",
        questionId: responseJson.message_id,
        questionTimestamp: responseJson.timestamp,
        error: false,
      };
    } catch (error: any) {
      if (error.name === "AbortError") {
        return {
          content:
            "The response generation has been stopped. You can enter a new question or resubmit a previous one to continue.",
          role: "assistant",
          error: true,
        };
      }

      return {
        content:
          "We're sorry, there was an unexpected error. Please try resubmitting your question. If you keep getting this message, contact us.",
        role: "assistant",
        error: true,
      };
    }
  }

  static async askQuestionDetails(
    chatDetails: ChatDetails,
    accessToken: string,
    request: Record<string, any>,
  ): Promise<ReturnedContent> {
    this.abortRequest();
    this.abortController = new AbortController();

    const { chatId, messageId } = chatDetails;
    const FORM_ENDPOINT =
      process.env.REACT_APP_BACKEND_URL +
      `/api/v1/chats/${chatId}/messages/${messageId}/form`;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "User-Agent": FETCH_USER_AGENT,
      },
      body: JSON.stringify(request),
      signal: this.abortController.signal,
      mode: "cors" as RequestMode,
    };

    try {
      const response = await fetch(FORM_ENDPOINT, requestOptions);

      if (response.status > 299) {
        // If Unauthorized - than log out
        switch (response.status) {
          case 400:
            throw new Error("Invalid request parameters.");

          case 401:
            throw new Error("The user is not authorized");

          case 404:
            // Note: it returns mocked data until .../form endpoint is done
            return pick(
              {
                chat_id: chatId!,
                message_id: messageId!,
                headers: [
                  {
                    name: "DC__OPERATIONS_DIVISION_NAME_HIST",
                    label: "Operations Division",
                    type: "string",
                  },
                  {
                    name: "DC__DC_NAME_HIST",
                    label: "Distribution Center",
                    type: "string",
                  },
                  {
                    name: "DC_TRUCK__TRUCK_ROUTE_NUMBER",
                    label: "Truck Route Number",
                    type: "int",
                  },
                  {
                    name: "DC_TRUCK__SHIP_TO_ADDRESS_BOOK_NUMBER",
                    label: "Ship to Address Book",
                    type: "string",
                  },
                  {
                    name: "DC_TRUCK__ACTUAL_DEPARTURE_DATE",
                    label: "Actual Departure",
                    type: "datetime",
                  },
                  {
                    name: "DC_TRUCK__EXPECTED_DEPARTURE_DATE",
                    label: "Expected Departure",
                    type: "datetime",
                  },
                  {
                    name: "DC_TRUCK__LATE_MINUTES_NUMBER",
                    label: "Late Minutes",
                    type: "int",
                  },
                ],
                data: [
                  [
                    "Central Division",
                    "ATLANTA",
                    2,
                    "ALT521",
                    "2023-07-10T17:58:00",
                    "2023-07-10T18:00:00",
                    0,
                  ],
                  [
                    "Central Division",
                    "ATLANTA",
                    2,
                    "ALT546",
                    "2023-07-10T17:58:00",
                    "2023-07-10T18:00:00",
                    0,
                  ],
                ],
                description:
                  "Timeliness of shipments, comparing actual versus expected departure times, and measures any delays in minutes",
                details: {
                  metric_name: "bill_of_landing_on_time_percentage",
                  parameters: {
                    dc_name: "ATLANTA",
                    date: "2023-07-10",
                  },
                },
              } as ChatDetailsformResponse,
              ["headers", "data"],
            );
          // Note: below lines should be uncommented once the backend endpoint is ready
          // throw new Error(
          //   "The specified chat, message, or data for the given date does not exist.",
          // );
          default:
            throw new Error();
        }
      }

      const responseJson = response.json();

      return responseJson;
    } catch (e: any) {
      console.error("Reuqest form error:", e);
      return {
        error: true,
        message: e.message,
      };
    }
  }
}
