import { ChatMessageAssistant } from "../types";
import ChatGPCLogomark from "./ChatGPCLogomark";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@bolt/components";

import "./ChatAssistantResponseMessageStyles.css";
import ChatAssistantResponseChartTab from "./ChatAssistantResponseChartTab";
import ChatAssistantResponseDetailsTab from "./ChatAssistantResponseDetailsTab";
import DatasetTable from "./DatasetTable";
import { memo } from "react";
import { ChatTheme } from "../hooks/useTheme";
import { uniq } from "lodash";
import MarkdownContent from "./MarkdownContent";

type ChatAssistantResponseMessageProps = {
  message: ChatMessageAssistant;
  theme: ChatTheme;
};

const ChatAssistantResponseMessage = ({
  message,
  theme,
}: ChatAssistantResponseMessageProps) => {
  const showVisualizationAndDataSourceTab = !!message.frame?.data;
  /* Note:
    SPEC-1931
    Show summary only when there is no Visualization tab.
    Otherwise show Summary above the chart at Visualization tab.
  */
  const showSummaryTab = !showVisualizationAndDataSourceTab && message.text;
  const showDetailsTab =
    message.details?.metric_name && message.details.metric_name !== "about";

  const defaultActiveTab = showVisualizationAndDataSourceTab
    ? "visualization"
    : "summary";

  const isBarChartFn = () => {
    const axisX =
      message.frame?.schema?.fields?.find((field) =>
        field.name.includes("_DATE"),
      )?.name || "";
    if (axisX) {
      const dates = message.frame?.data.map((row) => row[axisX]);
      if (uniq(dates).length === 1) {
        return true;
      }
    }

    return (
      JSON.stringify(message.details?.parameters?.date_to) ===
      JSON.stringify(message.details?.parameters.date_from)
    );
  };

  return (
    <div className="message bot-message">
      <ChatGPCLogomark className="chat-img" />
      <Tabs
        defaultValue={defaultActiveTab}
        variant="with-rule-ghost"
        className="answer-tabs"
      >
        <TabsList>
          {showSummaryTab && <TabsTrigger value="summary">Summary</TabsTrigger>}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="visualization">Visualization</TabsTrigger>
          )}

          {showVisualizationAndDataSourceTab && (
            <TabsTrigger value="data">Data Source</TabsTrigger>
          )}

          {showDetailsTab && <TabsTrigger value="details">Details</TabsTrigger>}
          {false && <TabsTrigger value="reasoning">Reasoning</TabsTrigger>}
        </TabsList>

        {showSummaryTab && (
          <TabsContent value="summary">
            <MarkdownContent>{message.text!}</MarkdownContent>
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent value="visualization" className="overflow-x-auto">
            <ChatAssistantResponseChartTab
              isBarChart={isBarChartFn()}
              message={message}
              metricName={message.details?.metric_name || ""}
              theme={theme}
            />
          </TabsContent>
        )}

        {showVisualizationAndDataSourceTab && (
          <TabsContent value="data" className="overflow-x-auto">
            <DatasetTable
              data={message.frame!.data}
              fields={message.frame!.schema.fields}
            />
          </TabsContent>
        )}

        {showDetailsTab && (
          <TabsContent value="details">
            <ChatAssistantResponseDetailsTab details={message.details!} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
};

export default memo(
  ChatAssistantResponseMessage,
  (prev, next) => prev.message.questionId === next.message.questionId,
);
