import {
  Pagination,
  PaginationItem,
  PaginationContent,
  PaginationItemCount,
  PaginationPrevious,
  PaginationStart,
  PaginationLink,
  PaginationNext,
  PaginationEnd,
} from "@bolt/components";
import { clamp, range } from "lodash";

import "./TablePaginationStyles.css";

// Note: The same type is used by TanStack Table
export type PaginationState = {
  pageIndex: number;
  pageSize: number;
};

type TablePaginationProps = {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  dataRows: any[];
};

const TablePagination = ({
  pagination,
  setPagination,
  dataRows,
}: TablePaginationProps) => {
  const lastPage = Math.ceil(dataRows.length / pagination.pageSize);
  const currentPage = pagination.pageIndex + 1;
  const pagesStart = clamp(currentPage - 2, 1, lastPage);
  const pagesEnd = Math.min(currentPage + 2, lastPage);
  const pages = range(pagesStart, pagesEnd + 1);

  return (
    <Pagination className="pagination">
      <PaginationContent>
        <PaginationItem>
          <PaginationItemCount itemsEnd={0} itemsStart={0} itemsTotal={0} />
        </PaginationItem>
        <PaginationItem>
          <PaginationStart
            isDisabled={pagination.pageIndex === 0}
            onClick={() =>
              setPagination((pagination) => ({
                pageIndex: 0,
                pageSize: pagination.pageSize,
              }))
            }
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationPrevious
            isDisabled={pagination.pageIndex === 0}
            onClick={() =>
              setPagination((pagination) => ({
                pageIndex: pagination.pageIndex - 1,
                pageSize: pagination.pageSize,
              }))
            }
          />
        </PaginationItem>
        {pages.map((pageNumber) => (
          <PaginationItem key={pageNumber}>
            <PaginationLink
              isActive={pagination.pageIndex + 1 === pageNumber}
              isDisabled={pagination.pageIndex + 1 === pageNumber}
              onClick={() =>
                setPagination((pagination) => ({
                  pageIndex: pageNumber - 1,
                  pageSize: pagination.pageSize,
                }))
              }
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            isDisabled={pagination.pageIndex + 1 >= lastPage}
            onClick={() =>
              setPagination((pagination) => ({
                pageIndex: pagination.pageIndex + 1,
                pageSize: pagination.pageSize,
              }))
            }
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationEnd
            isDisabled={pagination.pageIndex + 1 >= lastPage}
            onClick={() =>
              setPagination((pagination) => ({
                pageIndex: lastPage - 1,
                pageSize: pagination.pageSize,
              }))
            }
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default TablePagination;
