import React, { memo } from "react";

import "./ExampleQuestionTilesStyles.css";

const EXAMPLE_QUESTIONS = [
  "What was the inventory accuracy for 22 May 2023 in Boston compared to the target of 97.5%?",
  "Give me daily UPH for November 2023 in Central Division.",
  "Give me BOL % for Atlanta in March of 2024.",
  "What is the inventory accuracy for Los Angeles in May 2023? Goal: 95%.",
];

type ExampleQuestionTilesProps = {
  onClick: (prompt: string) => void;
};

const ExampleQuestionTiles = ({ onClick }: ExampleQuestionTilesProps) => {
  return (
    <div className="container-example-questions">
      {EXAMPLE_QUESTIONS.map((exampleQuestion) => (
        <button
          key={exampleQuestion}
          className="tile-example-question"
          onClick={() => onClick(exampleQuestion)}
        >
          {exampleQuestion}
        </button>
      ))}
    </div>
  );
};

export default memo(ExampleQuestionTiles);
