import {
  Alert,
  AlertContent,
  Autocomplete,
  Button,
  Loading,
} from "@bolt/components";

import "./ChatAssistantResponseChartTabDetailsStyles.css";
import { useState } from "react";
import { ChatGpcService } from "../services/ChatGpcService";
import { useUserInfoContext } from "../contexts/UserInfoContext";
import { useChatDetailsContext } from "../contexts/ChatContext";
import { DatasetTableData, DatasetTableSchemaFields } from "../types";
import DatasetTable from "./DatasetTable";

type RequestBody = {
  type: "breakdown";
  parameters: {
    date: string;
  };
};

type ChatAssistantResponseChartTabDetailsProps = {
  dateVariants: string[];
};

function mapResponseArrayDataToObjectData(
  headers: DatasetTableSchemaFields,
  data: Array<Array<number | string>>,
): DatasetTableData {
  const newDataArray = [...data];

  return newDataArray.map((dataRow) =>
    dataRow.reduce<Record<string, string | number>>(
      (currentObject, value, index) => {
        const fieldName = headers?.[index]?.name;
        currentObject[fieldName] = value;
        return currentObject;
      },
      {},
    ),
  );
}

const ChatAssistantResponseChartTabDetails = ({
  dateVariants,
}: ChatAssistantResponseChartTabDetailsProps) => {
  const [date, setDate] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const [table, setTable] = useState<{
    fields: DatasetTableSchemaFields;
    data: DatasetTableData;
  }>();
  const userInfo = useUserInfoContext();
  const chatDetails = useChatDetailsContext();

  const requestDetailedData = async () => {
    setIsLoading(true);
    setError(undefined);

    const request: RequestBody = {
      type: "breakdown",
      parameters: {
        date: date!,
      },
    };

    const response = await ChatGpcService.askQuestionDetails(
      chatDetails!,
      userInfo?.accessToken!,
      request,
    );
    if (response?.headers && response?.data) {
      setTable({
        data: mapResponseArrayDataToObjectData(response.headers, response.data),
        fields: response.headers,
      });
    } else if (response.error && response.message) {
      setError(response.message);
    } else {
      setError("Something went wrong :(");
    }

    setIsLoading(false);
  };

  const dataOptions = dateVariants.map((date) => ({
    label: date,
    value: date,
  }));

  dataOptions.unshift({ label: "select date", value: "" });

  return (
    <>
      <div className="message-details-form">
        <h4>
          <strong>Get BOL details for:</strong>
        </h4>
        <div className="fields-container">
          <Autocomplete
            className="date-input-with-autoselect"
            buttonLabel="Open select"
            buttonProps={{
              intent: "primary",
              size: "md",
              variant: "solid",
            }}
            data={dataOptions}
            inputSize="md"
            label="select date"
            multicolumnLimit={4}
            onSelect={(item) => setDate(item)}
            multiselect={false}
            selectedItem={date}
            placeholder="select date"
            showIcon
            trigger="input"
          />

          <Button
            size="md"
            variant="solid"
            intent="primary"
            disabled={!date}
            onClick={requestDetailedData}
          >
            Request details
          </Button>
        </div>
      </div>
      {isLoading && <Loading />}
      {!isLoading && table?.data && table?.fields && (
        <DatasetTable
          data={table.data}
          fields={table.fields}
          showDownloadCSVButton={false}
        />
      )}
      {!isLoading && error && (
        <Alert
          ariaLive="polite"
          showClose
          onDismiss={() => setError(undefined)}
          showLeftIcon
          size="sm"
          type="error"
          hasSeparator={false}
        >
          <AlertContent>{error}</AlertContent>
        </Alert>
      )}
    </>
  );
};

export default ChatAssistantResponseChartTabDetails;
